import React, { useState, useRef, useEffect } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { BaseModal } from "../../components/modals/base-modal";
import { ASSETS_URL } from "../../components/Store";
import { Img } from "../../components/shared/img";
import useIsMobile from "../../js/utilities/useIsMobile";

/* const screenfull = require("screenfull"); */

/**
 * Componente que renderiza la ventana modal de ver unas paginas en la ficha del libro
 * @param {object} props 
 * @returns - Preview
 */
export const Preview = ({ texts, titleBook, previewPages, isLogged, children }) => {
    const [toggler, setToggler] = useState(false);
    const [numberImg, setNumberImg] = useState(0);

    const visorScreen = useRef(null);
    const isMobile = useIsMobile();


    return (
        <>
            <button
                className={`opciones-libro  focusable-secondary mt-3 ${isLogged ? "btn-ver-pag" : ""}`}
                onClick={() => setToggler(!toggler)}
            >
                <div className="icon-opc">
                    <Icon icon="material-symbols-light:book-5" width={'100%'} />
                </div>

                <p>{texts?.botones?.ver || 'Ver unas páginas del libro'}</p>
            </button>
            <BaseModal
                windowClassName={'modal-preview-window'}
                overlayClassName={'modal-preview-overlay'}
                visible={toggler}
                setVisible={setToggler}
                backdropClose={true}
            >
                <div className="content-preview" ref={visorScreen}>


                    <div className="title-preview">
                        <h2>{texts?.modal?.preview?.titulo || 'Estas viendo solo tres páginas del libro:'}</h2>
                        <h3>{titleBook ? titleBook : "titulo del libro"}</h3>
                    </div>

                    {
                        isMobile ?
                            <div className="row">
                                <Pagination texts={texts} numberImg={numberImg} />

                                <div className="col-6">
                                    <div className="d-flex justify-content-end my-3">
                                        <CloseAndFullScreen setToggler={setToggler} />
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="d-flex justify-content-end my-3">
                                <CloseAndFullScreen setToggler={setToggler} />
                            </div>
                    }

                    <div className="row swiper-preview mt-3">
                        <div className="col-1 d-none d-lg-flex align-items-center">
                            <Previous numberImg={numberImg} setNumberImg={setNumberImg} />
                        </div>

                        <div className="col-lg-10">
                            <div className="imgs-preview mx-auto">
                                {
                                    previewPages &&
                                    previewPages.map((item, index) => {
                                        return numberImg === index && <Img key={`img${index}`} src={`${ASSETS_URL}${item}`} className="img-fluid h-100" />
                                    })
                                }

                                <div className="d-flex d-lg-none mt-3">
                                    <div className="col-3 px-0 d-flex align-items-center">
                                        <Previous numberImg={numberImg} setNumberImg={setNumberImg} />
                                    </div>
                                    <div className="col-6 d-flex justify-content-center align-items-center px-0">
                                        {children}
                                    </div>
                                    <div className="col-3 px-0 d-flex align-items-center justify-content-end ">
                                        <Next numberImg={numberImg} setNumberImg={setNumberImg} />
                                    </div>
                                </div>


                                {
                                    !isMobile &&
                                    <div className="d-flex mt-3">
                                        <Pagination texts={texts} numberImg={numberImg} />
                                        <div className="col-6 d-flex justify-content-end align-items-center px-0">
                                            {children}
                                        </div>
                                    </div>
                                }


                            </div>
                        </div>
                        <div className="col-1 d-none d-lg-flex align-items-center justify-content-end ">
                            <Next numberImg={numberImg} setNumberImg={setNumberImg} />
                        </div>
                    </div>

                </div>
            </BaseModal>

        </>
    )
}

const CloseAndFullScreen = ({ setToggler }) => {
    /* const [isScreenFull, setIsScreenFull] = useState(false);
    const visorScreen = useRef(null); */

    /* useEffect(() => {
        if (screenfull.isEnabled) {
            if (screenfull.isFullscreen) { return; }

            if (screenfull.isFullscreen === false) {
                setIsScreenFull(false);
            }
        }
    }, [screenfull.isEnabled, screenfull.isFullscreen]) */

    return (
        <>
            {/* <button
                aria-label="ver pantalla completa"
                className="circle-button screen mr-3 mr-lg-0"
                onClick={() => {
                    if (screenfull.isEnabled) {
                        screenfull.toggle(visorScreen.current);
                        if (screenfull.isFullscreen) {
                            setIsScreenFull(false);
                        } else {
                            setIsScreenFull(true);
                        }
                    }
                }}
            >
                {isScreenFull ?
                    <Icon icon="material-symbols:zoom-in-map" width="24" height="24" />
                    :
                    <Icon icon="material-symbols:zoom-out-map" width="24" height="24" />
                }

            </button> */}

            <button
                aria-label="Cerrar previsualización"
                onClick={() => setToggler(false)}
                className="circle-button close  mr-3 mr-lg-0"
            >
                <Icon icon="ep:close-bold" width="24" height="24" />
            </button>
        </>
    )
}

const Pagination = ({ texts, numberImg }) => {
    return (
        <div className="col-6 d-flex align-items-center pl-4 px-lg-0">
            <p className="secondary-font onsecondary--color">{texts?.modal?.preview?.pagina || 'Página'} {numberImg + 1} {texts?.modal?.preview?.de || 'de 3'}</p>
        </div>
    )
}

const Previous = ({ numberImg, setNumberImg }) => {
    return (
        <button
            aria-label="previous"
            className={` ${numberImg !== 0 ? "arrow-button active" : "arrow-button inactive"}`}
            onClick={() => numberImg !== 0 && setNumberImg(numberImg - 1)}
        >
            <Icon className="icon" icon="iconamoon:arrow-left-2-bold" width={50} />
        </button>
    )
}

const Next = ({ numberImg, setNumberImg }) => {
    return (
        <button
            aria-label="next"
            className={` ${numberImg !== 2 ? "arrow-button active" : "arrow-button inactive"}`}
            onClick={() => numberImg !== 2 && setNumberImg(numberImg + 1)}
            disabled={numberImg !== 2 ? false : true}
        >
            <Icon className="icon" icon="iconamoon:arrow-right-2-bold" width={50} />
        </button>
    )
}