import React, { useMemo, useEffect, useState, useContext } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { ASSETS_URL, store } from "../components/Store";
import MicroModal from "micromodal";
import { Icon } from '@iconify/react';
import { useBookData } from "./useBookData";
import useIsMobile from "../js/utilities/useIsMobile";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";

import { METADATA_TRANSLATE_FICHA } from "./informacionFicha/translateMetadata";
import normalizeString from "../js/utilities/normalizeString";
import _ from 'lodash';

//componentes

import ModalAlertas from "../components/ModalAlertas";
import { ModalCompartir } from "../components/modals/modal-compartir";
import InfoBasica from "./informacionFicha/InfoBasica";
import InfoDetalle from "./informacionFicha/InfoDetalle";
import SkeletonFichaLibro from "../skeletons/SkeletonsFichaLibro/SkeletonFichaLibro";
import { SkeletonRecomendados } from "../skeletons/SkeletonsFichaLibro/SkeletonRecomendados";

import { InvitacionLectura } from "./invitacion-lectura.js";
import { SkeletonTagsRecomendados } from "../skeletons/SkeletonsFichaLibro/SkeletonTagsRecomendados";
import { NotAvailableBook } from "./not-available-book";

import { SolicitudDemo } from "../components/solicitud-demo/index.js";

import { Helmet } from "react-helmet";
import { useBookSelection } from "./hooks/use-book-selection.js";
import { useContactRequest } from "../components/solicitud-demo/hook/useContactRequest.js";

import { CoverSection } from "./components/coverSection.js";
import { Activity } from "./components/activity.js";

/**
 * Componente que no recibe props. Este renderiza la pantalla de ficha del libro con su información correspondiente. Entre sus funciones principales estan obtener la información del libro, definir si el libro puede ser descargado y descargar libro. Este hace uso de los componentes Cover, ModalAlertas, ModalCompartir, InfoBasica, InfoDetalle, InvitacionLectura y AlertaLimite. 
 * @returns Ficha
 */
const Ficha = () => {
  const params = useParams();
  const { isReady, textosSubSecciones: textoFicha } = useObtenerSubsecciones('ficha_libro');

  const globalState = useContext(store);
  const { state } = globalState;
  const isLogged = state.sesionActiva !== null && state.sesionActiva === "SI";
  const isSmallCollection = globalState?.state?.cantidadLibros < 40;
  const idioma = state.idioma;
  const isMobile = useIsMobile();


  //Datos ficha
  let book = useBookData(params?.idLibro);
  const isFree = book?.metadata?.gratis === 1 || book?.metadata?.gratis === true;
  const metadata = useMemo(() => book.metadata, [book.metadata])
  const bookAvailable = (metadata?.territorio && typeof metadata?.territorio === "string" && metadata?.territorio?.trim()?.length > 0);

  const [urlLibro, setUrlLibro] = useState("");
  const [backs, setBacks] = useState([]);

  //Acciones ficha
  const [activeTab, setActiveTab] = useState("informacion-basica");

  const bookSelectionManager = useBookSelection({ bookId: params?.idLibro })
  const isBookSelectionMode = bookSelectionManager.isBookSelectionMode;

  const contactRequest = useContactRequest();

  const history = useHistory();

  useEffect(() => {
    if (isLogged) {
      contactRequest.setVisible(false)
    } else {
      contactRequest.setVisible(true)
    }
  }, []);

  //Url libro: actualiza el estado urlLibro
  useEffect(() => {
    let href;
    if (isLogged && state.cantidadLibros === 0) {
      href = "/mi-panel/mi-coleccion";
    }
    else if (isLogged && state.cantidadLibros > 0) {
      href = `/visor/${params?.idLibro}`;
    }
    else if (!isLogged && book?.metadata?.gratis === 1) {
      href = `/visor/${params?.idLibro}`;
    }
    else {
      href = `/login?bookT=/visor/${params?.idLibro}/`;
    }
    setUrlLibro(href);
  }, [book, isLogged, params.idLibro, state.cantidadLibros])

  /**define el orden de las imagenes de fondo, de los temas, de manera aleatoria */
  useEffect(() => {
    let fondos = [];
    let cantidadNumeros = 5;
    while (fondos.length < cantidadNumeros) {
      let numeroAleatorio = Math.ceil(Math.random() * cantidadNumeros);
      let existe = false;
      for (let i = 0; i < fondos.length; i++) {
        if (fondos[i] == numeroAleatorio) {
          existe = true;
          break;
        }
      }
      if (!existe) {
        fondos[fondos.length] = numeroAleatorio;
      }

    }
    setBacks(fondos)
  }, [])


  /**
   * Esta función se encarga de dibujar las figuras con los temas recomendados 
   */
  const temasDeInteres = () => {
    let temas = [];
    if (isMobile) {
      Object.keys(book.recommendedTags).map((tag, index) => {
        if (index < 2) {
          temas.push(
            <Link
              key={`tema-${index}`}
              to={`/busqueda?sch=${book.recommendedTags[tag]}`}
              className="ficha-tema mb-3"
              style={{ backgroundImage: `url('/img/ficha/back${backs[index]}.png')` }}
            >
              <span>{book.recommendedTags[tag]}</span>
            </Link>
          )
        }
      })
    } else {
      Object.keys(book.recommendedTags).map((tag, index) => {
        temas.push(
          <Link
            key={`tema-${index}`}
            to={`/busqueda?sch=${book.recommendedTags[tag]}`}
            className="ficha-tema mb-3"
            style={{ backgroundImage: `url('/img/ficha/back${backs[index]}.png')` }}
          >
            <span>{book.recommendedTags[tag]}</span>
          </Link>
        )
      })
    }
    return temas;
  }

  /**
     * Esta función retorna el link del blog en caso de que el libro este relacionado. 
   */
  const linkBlog = () => {
    if (metadata?.linkBlog !== undefined && metadata?.linkBlog !== '') {
      return (
        <Link
          to={metadata.linkBlog}
          className="ficha-link-blog focusable-secondary d-inline-block mb-4"
        >
          {textoFicha?.mas_detalles?.entrada || 'Entrada relacionada del blog'}{" "}
          <i className="fas fa-external-link-alt"></i>
        </Link>
      )
    }
  }


  /**
   * Esta función toma el texto de materia y asigna el texto correpondiente al idioma activo
   * @param {string} data equivale al texto de materia
   * @returns textoMateriaTag
   */
  const textoMateriaTag = (data) => {
    const lista = METADATA_TRANSLATE_FICHA['materia'];
    const normalizeValue = normalizeString(data);
    let path = '';
    lista.map((item) => {
      if (normalizeValue === item.nombre) {
        path = item.pathTranslate;
      }
    })

    return <p className="card-materia mb-2">{_.get(textoFicha, path) || 'N/A'} </p>
  }

  //render FICHA
  if (book?.loading?.bookData) {
    return (
      <SkeletonFichaLibro
        isLogged={isLogged}
        recomendados={book.recommendedBooks}
      />
    )
  } else {
    return (
      <>

        <Helmet>
          <title>{`MakeMake - ${metadata.titulo}`}</title>
          <meta name="description" content={idioma === 'es' ? metadata?.descripcion || 'N/A' : metadata?.descripcionEn || metadata?.descripcion || 'N/A'} />
          <link rel="canonical" href={`https://makemake.com.co/fichas/${params?.idLibro}/${params?.nombreLibro}`} />
        </Helmet>


        {isReady &&
          <>
            <div className="main-container">
              <InvitacionLectura />
              <div className={`container contenedor-ficha  ${isLogged ? 'px-4 px-lg-5 py-3' : 'px-4  pt-4 px-lg-5 pt-lg-5'}`}>
                {
                  isLogged && !bookSelectionManager.loading && isBookSelectionMode && bookAvailable && !isFree ?
                    <div className="book-selection-bar w-100 p-2 my-2 d-block px-3">
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <div className="">
                          <p className="font-weight-bold onbackground-primary--color">{textoFicha?.seleccion_libros?.libros_agregados || 'Libros añadidos'} <span className="secondary-inverse--color">{`(${bookSelectionManager.currentSelectedBooksLength}/${bookSelectionManager.maxBooks})`}</span></p>
                        </div>
                        <div className="d-flex align-items-center">
                          {
                            bookSelectionManager.isCurrentAdded ?
                              <>
                                <p className="font-weight-bold onbackground-primary--color mr-2 d-flex justify-content-center align-items-center"><Icon icon="ic:outline-check" className="" width={24} />{textoFicha?.seleccion_libros?.agregado || 'Añadido'}</p>
                                <button className="remove-button focusable-primary" onClick={() => bookSelectionManager.remove()}>{textoFicha?.seleccion_libros?.remover || 'Remover'}</button>
                              </>
                              :
                              <button className="add-button focusable-primary mr-2" onClick={() => bookSelectionManager.add()} disabled={bookSelectionManager.currentSelectedBooksLength > bookSelectionManager.maxBooks - 1}>{textoFicha?.seleccion_libros?.agregar || 'Agregar'}</button>
                          }

                        </div>
                      </div>
                    </div>
                    :
                    null
                }
                {
                  !isLogged && !bookAvailable &&
                  <NotAvailableBook />
                }
                <h1 className="tituloFicha mb-2 mb-lg-3">{metadata.titulo}</h1>


                <div className={`row py-lg-3 ${isLogged && 'mb-5'}`}>
                  {!isMobile &&
                    <CoverSection
                      metadata={metadata}
                      textoFicha={textoFicha}
                    />
                  }


                  <section className="col-lg-8">
                    <nav className="nav-ficha d-flex">
                      <button
                        onClick={() => setActiveTab("informacion-basica")}
                        aria-current={activeTab === "informacion-basica" ? 'page' : false}
                        className={`px-2 px-lg-3 py-2 nav-button-ficha focusable-red ${activeTab === "informacion-basica" && 'nav-button-ficha-active'}`}
                      >
                        {textoFicha?.info_basica?.titulo_pestana || 'Información básica'}
                      </button>
                      {isLogged &&
                        <button
                          aria-current={activeTab === "mas-detalles" ? 'page' : false}
                          onClick={() => setActiveTab("mas-detalles")}
                          className={`px-2 px-lg-3 py-2 ml-3 nav-button-ficha focusable-red ${activeTab === "mas-detalles" && 'nav-button-ficha-active'}`}
                        >
                          {textoFicha?.mas_detalles?.titulo_pestana || 'Más detalles'}
                        </button>
                      }
                    </nav>

                    <div className="px-lg-2 py-4 px-lg-5 content-info-ficha">
                      {
                        !isMobile &&
                        <Activity metadata={metadata} textoFicha={textoFicha} />
                      }

                      {activeTab === "informacion-basica" ?
                        <InfoBasica
                          textoFicha={textoFicha}
                          metadata={metadata}
                          idioma={idioma}
                        />
                        :
                        <InfoDetalle textoFicha={textoFicha} metadata={metadata} idioma={idioma} />
                      }

                      <div>
                        {/* blog */}
                        {linkBlog()}

                        {/* leer */}
                        <div className={`row mx-0 justify-content-md-end mt-3`}>

                          {
                            (isLogged || (isLogged && bookAvailable)) &&
                            <Link
                              to={urlLibro}
                              className="ficha-btn-enviar focusable-red"
                              onClick={(e) => {
                                if (metadata.limiteDeLecturasAlcanzado) {
                                  e.preventDefault();
                                  MicroModal.show("modal-alerts", {
                                    awaitCloseAnimation: true,
                                  });
                                }
                              }}
                            >
                              {textoFicha?.botones?.leer || 'Leer'}
                            </Link>
                          }
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                {
                  !isSmallCollection && isLogged &&
                  <section>
                    <div className="ficha-subtitle">
                      <h2>{textoFicha?.interfaz?.otros_temas || 'Otros temas de tu interés'}</h2>
                      <hr />
                    </div>

                    <div className="content-temas my-4">
                      <div className="d-flex justify-content-center justify-content-lg-between flex-wrap">
                        {book.loading.recommendedTagsData ?
                          <SkeletonTagsRecomendados recommendedTags={book.recommendedTags} />
                          :
                          temasDeInteres()
                        }
                      </div>
                    </div>

                    <div className="d-flex justify-content-end my-3">
                      <Link to="/curadurias/colecciones" className="ficha-btn-ver-mas">
                        <span>{textoFicha?.botones?.recomendaciones || 'Ver más recomendaciones'}</span>
                        <Icon icon="mdi:plus-circle" className="mx-1" width={30}></Icon>
                      </Link>
                    </div>
                  </section>

                }
                {
                  !isSmallCollection && isLogged &&
                  <section>
                    <div className="ficha-subtitle">
                      <h2>{textoFicha?.interfaz?.otros_libros || 'Otros libros similares'}</h2>
                      <hr />
                    </div>
                    <div>
                      {
                        book.loading.recommendedBooksData ?
                          <SkeletonRecomendados />
                          :
                          <div className="row mx-0 justify-content-center my-4 my-lg-3">
                            {book.recommendedBooks.map((item, index) => {
                              if (item.link !== null && (index + 1) !== book.recommendedBooks.length) {
                                return (
                                  <div className="col-lg-6 px-4 mb-4 mb-lg-0" key={`recomendado-${index}`}>
                                    <Link
                                      key={index}
                                      to={item.link}
                                      className="d-sm-flex ficha-card-libro"
                                    >
                                      <div className="col-sm-4 px-0 card-portada "
                                        style={{ backgroundImage: `url(${ASSETS_URL}${item.portada})` }}
                                      ></div>
                                      <div className="col-sm-8 px-0 card-descipcion">
                                        <div className="card-title p-3">
                                          <h3>{item.titulo} </h3>
                                        </div>


                                        <div className="p-3">
                                          <p className="card-autor">{item?.autor || 'N/A'}</p>
                                          <p className="card-editorial">{item?.editorial || 'N/A'} </p>
                                          {textoMateriaTag(item.materia)}
                                          <p>{idioma === 'es' ? item?.descripcion || 'N/A' : item.descripcionEn || item?.descripcion || 'N/A'}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                );
                              }
                            })}
                          </div>
                      }
                    </div>
                  </section>
                }

                {contactRequest?.state?.visible && <div className="gradient-demo"></div>}
              </div>

              {contactRequest?.state?.visible &&
                <SolicitudDemo
                  className={'ficha-demo'}
                  keepReading={() => history.push('/libros')}
                  txtButton={textoFicha?.botones?.catalogo || 'ver más libros'}
                />
              }


            </div>


            <ModalAlertas
              id="modal-alerts"
              message={textoFicha?.mas_detalles?.modal_alerta?.mensaje || "Ya no tienes libros disponibles"}
              description={
                textoFicha?.mas_detalles?.modal_alerta?.descripcion || "Has superado el límite de libros que puedes leer en un día. Para ampliar la capacidad de tu suscripción, escríbenos a contacto@makemake.com.co`"
              }
            />
            <ModalCompartir idModal={'modal-compartir-libro'} libro={metadata} />
          </>
        }
      </>
    )
  }

}

export default Ficha;