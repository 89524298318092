import React, { useContext, useState, useEffect } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useParams, Link } from "react-router-dom";
import MicroModal from "micromodal";

import { ASSETS_URL, librosMaxDescargados, store } from "../../components/Store";
import { FavoritesStorage } from "../../util/favorites-storage";
import { ModoPWAContext } from "../../contexts/ModoPwaContext";

import { Preview } from "./preview";
import { Cover } from "../Cover";
import { Activity } from "./activity";
import AlertaLimite from "../AlertaLimite";

import { useBookData } from "../useBookData";
import { useBookDownloader } from "../../offline/hooks/use-book-downloader";
import { useDownloadedBooksManager } from "../../offline/hooks/use-downloaded-books-manager";
import { useFavoritesBooks } from "../../hooks/useFavoritesBooks";
import useIsMobile from "../../js/utilities/useIsMobile";

/**
 * Componente que el area de la portada en la ficha del libro
 * @param {object} metada - informacion del libro 
 * @param {object} textoFicha - textos de interfaz
 * @returns CoverSection
 */
export const CoverSection = ({ metadata, textoFicha }) => {
    const globalState = useContext(store);
    const { state } = globalState;
    const pwaState = useContext(ModoPWAContext);
    const [idLibro, setIdLibro] = useState("");
    const [descargado, setDescargado] = useState(false);
    const [urlLibro, setUrlLibro] = useState("");
    const params = useParams();
    const isMobile = useIsMobile();

    let book = useBookData(params?.idLibro);
    const downloadedBooksManager = useDownloadedBooksManager();
    const downloadedBooks = downloadedBooksManager?.books;
    const downloadedBooksLength = downloadedBooks?.length;

    const isLogged = state.sesionActiva !== null && state.sesionActiva === "SI";
    const bookAvailable = (metadata?.territorio && typeof metadata?.territorio === "string" && metadata?.territorio?.trim()?.length > 0);

    //Libro favorito
    const user = globalState.state.username;
    const favorites = useFavoritesBooks({ userName: user })
    const [favorite, setFavorite] = useState(false);

    //Datos descarga
    const [alertaLimite, setAlertaLimite] = useState(false);

    const previewPages = [metadata.screen1, metadata.screen2, metadata.screen3];

    useEffect(() => {
        setIdLibro(params.idLibro);
    }, [params.idLibro]);

    //Url libro: actualiza el estado urlLibro
    useEffect(() => {
        let href;
        if (isLogged && state.cantidadLibros === 0) {
            href = "/mi-panel/mi-coleccion";
        }
        else if (isLogged && state.cantidadLibros > 0) {
            href = `/visor/${params?.idLibro}`;
        }
        else if (!isLogged && book?.metadata?.gratis === 1) {
            href = `/visor/${params?.idLibro}`;
        }
        else {
            href = `/login?bookT=/visor/${params?.idLibro}/`;
        }
        setUrlLibro(href);
    }, [book, isLogged, params.idLibro, state.cantidadLibros])

    /**Define si el libro pertenece a los favoritos.*/
    useEffect(() => {
        let isFavorite = favorites.includes(params.idLibro);
        setFavorite(isFavorite);
    }, [params.idLibro, favorites])


    /** Agrega o elimina el libro los favoritos en el localstorage*/
    const agregarRemoverAFavoritos = () => {
        if (favorite === false) {
            FavoritesStorage.saveFavorites(user, idLibro);
        } else {
            FavoritesStorage.removeFavorites(user, idLibro);
        }
    }

    const bookDownloader = useBookDownloader({
        book, onSuccess: () => {
            setDescargado(true);
        }
    });

    const handleDownload = () => {
        bookDownloader.download();
        if (downloadedBooksLength >= librosMaxDescargados) {
            setAlertaLimite(true);
            return;
        }
    }

    useEffect(() => {
        if (Array.isArray(downloadedBooks)) {
            const isDownloaded = downloadedBooks?.find(book => book?.bookId === params.idLibro);
            setDescargado(isDownloaded);
        }
    }, [downloadedBooks, params.idLibro])

    return (
        <>
            {alertaLimite && (
                <AlertaLimite setAlertaLimite={setAlertaLimite} />
            )}

            <aside className="col-lg-4 mb-4 mb-lg-0 px-0 px-lg-3">
                <div className="cover-container">
                    {isMobile &&
                        <Activity metadata={metadata} textoFicha={textoFicha} />
                    }
                    <Cover metadata={{ ...metadata, portada: `${ASSETS_URL}${metadata?.portada}` }} urlLibro={urlLibro} />


                    {/* leer */}
                    {
                        (isLogged || (!isLogged && bookAvailable)) &&
                        <Link
                            to={urlLibro}
                            className="btn btn-primary focusable-red px-5 mt-4 mx-auto d-block col-sm-10"
                            style={{ maxWidth: 285 }}
                            onClick={(e) => {
                                if (metadata?.limiteDeLecturasAlcanzado) {
                                    e.preventDefault();
                                    MicroModal.show("modal-alerts", {
                                        awaitCloseAnimation: true,
                                    });
                                }
                            }}
                        >
                            {textoFicha?.botones?.leer || 'Leer'}
                        </Link>
                    }
                </div>


                <div className="d-flex d-lg-block">

                    {/* favorito */}
                    {
                        isLogged &&
                        <div className="col-6 col-lg-12 px-0">
                            <button
                                className="mt-3 btn-favorito focusable-secondary"
                                onClick={() => agregarRemoverAFavoritos()}
                            >
                                <div className="icon-opc">
                                    {favorite ?
                                        <Icon icon="material-symbols:favorite" className="mx-1 red-inverse--color" width={'100%'} />
                                        :
                                        <Icon icon="material-symbols:favorite-outline" className="mx-1" width={'100%'} />
                                    }
                                </div>

                                <p>{textoFicha?.botones?.favorito || 'Marcar como favorito'}</p>

                            </button>
                        </div>
                    }

                    {/* ver unas paginas */}
                    {
                        (isLogged || (!isLogged && bookAvailable)) &&

                        <div className={`${isLogged ? "col-6 col-lg-12 px-0" : "col-12"}`}>
                            <Preview
                                texts={textoFicha}
                                titleBook={metadata.titulo}
                                previewPages={previewPages}
                                isLogged={isLogged}
                            >
                                {
                                    (isLogged || (!isLogged && bookAvailable)) &&
                                    <Link
                                        to={urlLibro}
                                        className="btn btn-primary secondary-font focusable-red px-5 d-block col-sm-10 col-lg-9"
                                        onClick={(e) => {
                                            if (metadata?.limiteDeLecturasAlcanzado) {
                                                e.preventDefault();
                                                MicroModal.show("modal-alerts", {
                                                    awaitCloseAnimation: true,
                                                });
                                            }
                                        }}
                                    >
                                        {textoFicha?.botones?.leer || 'Leer'}
                                    </Link>
                                }
                            </Preview>
                        </div>
                    }
                </div>



                <div className="d-flex mt-4 buttons-box">
                    <div className="col-6 p-0">
                        {/* Boton de descargar libro */}
                        {(() => {
                            if (isLogged && pwaState?.clienteHabilitado && pwaState?.compatible) {
                                return (
                                    /* className="ficha-botones-descarga" */
                                    <div aria-live="assertive" className="ficha-botones-descarga h-100 pr-1">
                                        {(() => {
                                            if (descargado) {
                                                return (
                                                    <Link to={'/descargados'} className="d-flex w-100 h-100 justify-content-center align-items-center ficha-btn-descargado  focusable-secondary" aria-label="libro descargado" disabled>
                                                        {textoFicha?.botones?.descargado || 'Descargado'}
                                                    </Link>
                                                );
                                            } else if (alertaLimite) {
                                                return (
                                                    <button
                                                        className="button-option-book focusable-secondary"
                                                        disabled
                                                    >
                                                        {textoFicha?.botones?.descargar || 'Descargar'}
                                                        <Icon icon="jam:download" width="24" height="24" />
                                                    </button>
                                                );
                                            } else {
                                                return (
                                                    <button
                                                        className="button-option-book focusable-secondary"
                                                        disabled={state.descargando}
                                                        onClick={() => handleDownload()}
                                                    >
                                                        {textoFicha?.botones?.descargar || 'Descargar'}
                                                        <Icon icon="jam:download" width="24" height="24" />
                                                    </button>
                                                );
                                            }
                                        })()}
                                    </div>
                                )
                            }
                        })()}
                    </div>

                    <div className="col-6 p-0">
                        {
                            isLogged &&
                            <button
                                className="button-option-book focusable-red"
                                onClick={() => {
                                    MicroModal.show('modal-compartir-libro', {
                                        awaitCloseAnimation: true
                                    });
                                }}
                            >
                                {textoFicha?.botones?.compartir || 'Compartir'}
                                <Icon icon="material-symbols-light:share" width="20" height="20" className="ml-2" />
                            </button>
                        }
                    </div>
                </div>

            </aside>
        </>
    )
}