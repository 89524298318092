import React, { useContext } from "react";
import normalizeString from "../../js/utilities/normalizeString";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import { ProtectedLinkFicha } from "../protected-link-ficha";
import { store } from "../../components/Store";

/**
 * Este componente recibe los props necesarios para mostrar la información detallada del libro en la sección de la Ficha.
 * @returns InfoDetalle
 */
const InfoDetalle = (props) => {
  const { metadata, textoFicha, idioma } = props;
  const expresion = /\s?\,\s?|\s\y\s?/;
  const globalState = useContext(store);
  const { state } = globalState;
  const country = state?.pais?.toLowerCase();

  return (
    <>
      <div className="row">
        <div className="col-12">
          {/* Autor */}
          <div className="mb-4 d-flex ">
            <p className="fichalibro-data--name col-6 col-lg-3 mr-1" style={{ textTransform: "uppercase" }}>
              {metadata?.autor?.split(',').length > 1 ?
                textoFicha?.info_basica?.autores || 'Autores'
                :
                textoFicha?.info_basica?.autor || 'Autor'
              }
            </p>
            {metadata?.autor?.split(expresion)?.map((item, index) => {
              return (
                <div key={`autor-${index}`} className="d-inline">
                  <ProtectedLinkFicha
                    to={`/busqueda?sch=${item}`}
                    aria-label={`${textoFicha?.info_basica?.autor || 'Autor'} ${item} `}
                    className="fichalibro-data--link focusable-secondary"
                  >
                    {item}
                  </ProtectedLinkFicha>
                  <span className="fichalibro-data--link mr-3">{metadata.autor.split(expresion).length !== (index + 1) && ','}</span>
                </div>

              )
            })}

          </div>

        </div>
        <div className="col-lg-8">

          {/* codigo BISAC*/}
          <div className=" mb-4 d-flex">
            <p className="fichalibro-data--name col-6 col-lg-5" style={{ textTransform: "uppercase" }}>
              {textoFicha?.mas_detalles?.codigo_bisac || 'Código BISAC'}
              <span
                className="ml-2"
                role="tooltip"
                data-place="top"
                data-tip={textoFicha?.mas_detalles?.bisac?.tooltip || "BISAC (Book Industry Standards and Communications) es un código estandarizado para la transferencia electrónica de información de materias. Los títulos de asignaturas de BISAC describen el contenido temático de un libro diferenciado por materia, rango de edad y género"}
                data-for="tooltiptext"
              >
                <i className="fas fa-question-circle fa-fw tooltip" />
              </span>
            </p>
            <ReactTooltip
              className={"tooltiptext"}
              clickable={true}
              effect={"solid"}
              event={"mouseenter"}
              globalEventOff={"click"}
              html={true}
              id="tooltiptext"
              type={"info"}
            />

            <p className="fichalibro-data--info">{metadata?.codigoBISAC}</p>

          </div>

          {/* Fecha */}
          <div className=" mb-4 d-flex">
            <p className="fichalibro-data--name col-6 col-lg-5" style={{ textTransform: "uppercase" }}>{textoFicha?.mas_detalles?.fecha || 'Fecha de publicación'}</p>
            <p className="fichalibro-data--info">{metadata?.fecha}</p>
          </div>

          {/* Idioma */}
          <div className=" mb-4 d-flex">
            <p className="fichalibro-data--name col-6 col-lg-5" style={{ textTransform: "uppercase" }}>{textoFicha?.mas_detalles?.idioma || 'Idioma'}</p>
            {
              metadata?.idioma?.map((item, i) => {
                if (item !== '') {
                  return (
                    <div key={`idioma-${i}`} className="d-inline">
                      <p className="fichalibro-data--info d-inline">{item}</p>
                      <span className="fichalibro-data--info mr-3">{metadata.idioma?.length !== (i + 1) && ','}</span>
                    </div>
                  )
                }

              })
            }

          </div>

          {/* tiempo de lectura */}
          <div className=" mb-4 d-flex">
            <p className="fichalibro-data--name col-6 col-lg-5" style={{ textTransform: "uppercase" }}>{textoFicha?.mas_detalles?.tiempo || 'Tiempo de lectura'}</p>
            <div className="d-flex align-items-center">
              <i className="far fa-hourglass ficha-icon-tiempo mr-2"></i>
              <p className="fichalibro-data--info">{metadata?.tiempoLectura} min</p>
            </div>

          </div>

          {/* tipo de libro */}
          {
            metadata?.tipoDeLibro &&
            <div className=" mb-4 mb-lg-5 d-flex">
              <p className="fichalibro-data--name col-6 col-lg-5" style={{ textTransform: "uppercase" }}>{textoFicha?.mas_detalles?.tipo_libro || 'Tipo de libro'}</p>

              <div className="d-flex align-items-center">
                <img
                  src={`/img/ficha/${metadata?.tipoDeLibro ? normalizeString(metadata?.tipoDeLibro) : 'no-icon'}.png`}
                  alt="icono tipo de libro"
                  aria-hidden={true}
                  className="ficha-icon-tipoLibro mr-2"
                />
                <p className="fichalibro-data--info">{metadata?.tipoDeLibro || 'N/A'}</p>
              </div>

            </div>
          }

        </div>


        <div className="col-lg-4 my-3">

          <div className="d-flex flex-wrap d-lg-block">
            <div className="col-6 col-lg-12 px-1 mb-4">
              {/* edad */}
              <ProtectedLinkFicha
                className="ficha-btn-edad py-1 focusable-red"
                data-edad={`Desde ${metadata?.edad} años`}
                to={`/busqueda?edad=${metadata?.edad}`}
              >
                {textoFicha?.mas_detalles?.edad?.edad || 'Edad'} {textoFicha?.mas_detalles?.edad?.desde || 'Desde'} {metadata?.edad} {textoFicha?.mas_detalles?.edad?.anios || 'años'}
              </ProtectedLinkFicha>
            </div>

            <div className="col-6 col-lg-12 px-1 mb-4">
              {/* Libro premiado */}
              {metadata?.libroPremiado === 1 &&
                <ProtectedLinkFicha to={`/busqueda?libroPremiado=true`} className="ficha-btn-feature px-4 py-3">
                  <img
                    src="/img/ficha/premiado.png"
                    alt="icono de libro premiado"
                    aria-hidden={true}
                    className="ficha-btn-premiado mr-3"
                  />
                  <p>{textoFicha?.mas_detalles?.libro_premiado || 'Libro premiado'}</p>
                </ProtectedLinkFicha>
              }
            </div>


            {/*GR Level*/}
            {metadata?.dificultadLectora && country === 'us' &&
              <div className="col-6 col-lg-12 px-1 mb-4">
                <ProtectedLinkFicha to={`/busqueda?dificultadLectora=${metadata?.dificultadLectora}`} className="ficha-btn-feature px-2 py-3 btn-reading-level">
                  <p className="gr-level mb-3 ">{metadata?.dificultadLectora}</p>
                  <p>{'Guided Reading Level'}</p>
                </ProtectedLinkFicha>
              </div>
            }


            <div className="col-6 col-lg-12 px-1 mb-4">
              {/*Libro accesible  */}
              {metadata?.libroAccesible === 1 &&
                <ProtectedLinkFicha to={`/busqueda?libroAccesible=true`} className="ficha-btn-feature  px-4 py-3">
                  <i className="fa fa-universal-access ficha-btn-acc mr-3"></i>
                  <p>{textoFicha?.mas_detalles?.libro_accesible || 'Libro accesible'}</p>
                </ProtectedLinkFicha>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default InfoDetalle;

InfoDetalle.propTypes = {
  /**
   * Objeto que contiene los textos de la ficha. Estos se actualizan según del idioma activo
   */
  textoFicha: PropTypes.object.isRequired,
  /**
   * Objeto que contiene la información del libro 
   */
  metadata: PropTypes.object.isRequired
}