import React, { useState, useEffect, useContext } from "react";
import { store } from "../../components/Store";

import { AccesibilidadContext } from "../../contexts/AccesibilidadContext";
import { ASSETS_URL } from "../../components/Store";
import { CommunitySignInRequestModal } from "../../components/community/community-sign-in-request-modal";

/**
 * Componente que retorna el boton de incluye guia de lectura en la ficha de libro
 * @param {object} metada - informacion del libro 
 * @param {object} textoFicha - textos de interfaz
 * @returns Activity
 */
export const Activity = ({ metadata, textoFicha }) => {
    const globalState = useContext(store);
    const { state } = globalState;
    const isLogged = state.sesionActiva !== null && state.sesionActiva === "SI";

    const accebilidadState = useContext(AccesibilidadContext);
    const [esTemaClasico, setEsTemaClasico] = useState(true);
    const [showNoAuthModal, setShowNoAuthModal] = useState(false);

    /*Actualiza el estado de EsTemaClasico. */
    useEffect(() => {
        let tema = accebilidadState?.temaActual !== 'clasico' ? false : true;
        setEsTemaClasico(tema);
    }, [accebilidadState])

    const handleNoAuthUser = () => {
        setShowNoAuthModal(true);
    }

    return (
        <>
            {/* actividad */}
            {metadata?.tieneActividad === true && (
                <a
                    href={`${ASSETS_URL}${metadata?.Actividad[0]?.webURL}`}
                    onClick={(e) => {
                        if (isLogged !== true) {
                            e.preventDefault();
                            handleNoAuthUser();
                        }
                    }}
                    target="_black"
                    download={true}
                    className="ficha-actividad d-flex justify-contente-center align-items-center">
                    <img
                        src={`${esTemaClasico ? "/img/icons/libreta.svg" : "/img/icons/libreta-alt.svg"}`}
                        alt="imagen de bombilla"
                        className="mr-3 secondary-inverse--color"
                    />
                    <p>{textoFicha?.mas_detalles?.incluye || 'Incluye actividad'}</p>
                </a>
            )}

            <CommunitySignInRequestModal
                visible={showNoAuthModal}
                setVisible={setShowNoAuthModal}
                title={textoFicha?.popups?.iniciar_sesion?.titulo || "Contenido exclusivo!"}
                description={
                    textoFicha?.popups?.iniciar_sesion?.descripcion || "Para acceder a este contenido inicia sesión."
                }
                buttonText={textoFicha?.popups?.iniciar_sesion?.boton || "Iniciar sesión"}
            />
        </>
    )
}